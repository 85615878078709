import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import sparkling from './sparkling.gif';
import {Trans} from "react-i18next";

const BannerContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: rgba(71,82,93,.8);
`;

const Content = styled.div`
  width: 600px;
  height: 400px;
  background: #ff5454;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed;
  
  ul {
    li {
      font-size: 22px;
    }
  }
`;

const Title = styled.div`
  width: 100%;
  padding-left: 80px;
  margin-bottom: 8px;
  font-size: 32px;
`;


const NotificationBanner = () => {
  const [ status, setStatus ] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      setStatus(true)
    }, 1000);
  }, [])
  
  if(!status) {
    return <div></div>
  }
  
  return (  
    <BannerContainer onClick={()=>setStatus(false)} > 
      <Content className="animate__animated animate__bounceInDown">
        <img src={sparkling} style={{position: "absolute", height: '60%', left: '0', top: '0'}} />
        <Title>
          <Trans>
            promo_popup_type_the_code
          </Trans>
          <strong>FREE</strong></Title>
        <ul>
          <li>
            <Trans>
              promo_popup_free_until
            </Trans>
          </li>
          <li>
            <Trans>
              promo_popup_cost
            </Trans>
          </li>
          <li>
            <Trans>
              promo_popup_cancel_anytime
            </Trans>
          </li>
        </ul>
      </Content>
    </BannerContainer>
  )
}

export default NotificationBanner

import React from 'react'
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import Title from './Title';

import { useHistory } from "react-router";

import Button from './Button';

import elegant from '../assets/icons/elegant.svg';
import autonome from '../assets/icons/autonome.svg';
import facile from '../assets/icons/facile.svg';
import secure from '../assets/icons/secure.svg';
import world from '../assets/icons/world.svg';
import gratuit from '../assets/icons/gratuit.svg';

import i18n from "i18next";
import {Trans} from "react-i18next";



const WhyUsSectionContainer = styled.div`
  min-height: 623px;
  display: flex;
  align-items: center;
  padding: 32px 0px;
`

const ServiceItem = styled.div`
  width: 100%;
  max-width: 300px;
  height: 250px;
  margin: 4px;
  color: #000;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
    
  .title {
    font-size: 20px;
    text-align: center;
    margin-top: 16px;
  }
  
  .description {
    text-align: center;
    margin-top: 4px;
  }
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Icon = styled.img`
  height: 50px;
  width: 50px;
`;

const StartNowButtonContainer = styled.div`
  width: 300px;
  margin-left:auto;
  margin-right:auto;
`


const WhyUsSection = () => {

  const history = useHistory();

  const services = [
    {
      icon: elegant,
      title: i18n.t("landingpage_whyus_title1"),
      description: i18n.t("landingpage_whyus_description1")
    },
    {
      icon: facile,
      title: i18n.t("landingpage_whyus_title2"),
      description: i18n.t("landingpage_whyus_description2")
    },
    {
      icon: gratuit,
      title: i18n.t("landingpage_whyus_title3"),
      description: i18n.t("landingpage_whyus_description3")
    },
    {
      icon: autonome,
      title: i18n.t("landingpage_whyus_title4"),
      description: i18n.t("landingpage_whyus_description4")
    },
    {
      icon: secure,
      title: i18n.t("landingpage_whyus_title5"),
      description: i18n.t("landingpage_whyus_description5")
    },
    {
      icon: world,
      title: i18n.t("landingpage_whyus_title6"),
      description: i18n.t("landingpage_whyus_description6")
    },
  ]

  return (
    <WhyUsSectionContainer>
      <Container>
        <Title>
          <Trans>
            landingpage_whyus_title_main
          </Trans>
        </Title>
        <ServicesContainer>
          {
            services.map((service, index)=>{
              return (
                <ServiceItem key={index}>
                  <Icon src={service.icon} />
                  <div className="title">{service.title}</div>
                  <div className="description">{service.description}</div>
                </ServiceItem>
              )
            })
          }
        </ServicesContainer>

        <StartNowButtonContainer>
          <Button onClick={()=> {
            history.push('/register')
          }}>
            <Trans>
              landingpage_main_start_now
            </Trans>
          </Button>
        </StartNowButtonContainer>

      </Container>
    </WhyUsSectionContainer>
  )
}

export default WhyUsSection

import React from 'react'
import Typography from '@material-ui/core/Typography';



const Title = ({children, color = "#000"}) => {
  return (
    <Typography variant="h3" component="h3" align="center">
      <div style={{color, margin: '42px 0px'}}>{children}</div>
    </Typography>
  )
}

export default Title

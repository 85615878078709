import React from 'react'
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Title from './Title';

import howToStep1 from '../assets/how-to-images/1.png';
import howToStep2 from '../assets/how-to-images/2.png';
import howToStep3 from '../assets/how-to-images/3.png';

import howToStep1Fr from '../assets/how-to-images/fr_registration.jpg';
import howToStep2Fr from '../assets/how-to-images/fr_qr.jpg';
import howToStep3Fr from '../assets/how-to-images/fr_form.jpg';

import howToStep1Nl from '../assets/how-to-images/nl_registration.jpg';
import howToStep2Nl from '../assets/how-to-images/nl_qr.jpg';
import howToStep3Nl from '../assets/how-to-images/nl_form.jpg';

import i18n from "i18next";
import {Trans} from "react-i18next";


const Section = styled.div`
  color: #000000DE;
  text-align: center;
  margin-bottom: 32px;
  
  .title {
    font-size: 32px;
    color: #ffffff;
  }
  
  .description {
    margin: 16px 0px;  
    color: #ffffff;
  }
  
  .image-container {
    img {
      width: 400px;
    }
  }
`

const PricingSectionContainer = styled.div`
  min-height: 623px;
  display: flex;
  align-items: center;
  padding: 32px 0px;
  background-color: #303030;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const PricingSection = () => {

  const steps = [
    {
      title: '1.',
      description: i18n.t("landingpage_howto_description1"),
      image: howToStep1
    },
    {
      title: '2.',
      description: i18n.t("landingpage_howto_description2"),
      image: howToStep2
    },
    {
      title: '3.',
      description: i18n.t("landingpage_howto_description3"),
      image: howToStep3
    }
  ];

  if(i18n.language == "fr"){
    steps[0]['image'] = howToStep1Fr;
    steps[1]['image'] = howToStep2Fr;
    steps[2]['image'] = howToStep3Fr;
  } else if(i18n.language == "nl"){
    steps[0]['image'] = howToStep1Nl;
    steps[1]['image'] = howToStep2Nl;
    steps[2]['image'] = howToStep3Nl;
  }

  return (
    <PricingSectionContainer>
      <Container>  
        <Title color="#fff">
          <Trans>
            landingpage_howto_title_main
          </Trans>
        </Title>
        <ContentContainer>
          {
            steps.map((step, index) => (
              <Section key={index}>
                <div className="title">{step.title}</div>
                <div className="description">{step.description}</div>
                <div className="image-container"><img src={step.image}/></div>
              </Section>
            ))
          }
        </ContentContainer>
      </Container>
    </PricingSectionContainer>
  )
}

export default PricingSection

import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col, Form, Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import {emphasize} from "@material-ui/core";


import {loadStripe} from "@stripe/stripe-js";
import {Elements, CardElement, useStripe, useElements, ElementsConsumer} from '@stripe/react-stripe-js';
import NotificationBanner from './../NotificationBanner';

import { config } from '../../_helpers/C';

import {Trans} from "react-i18next";
import i18n from "i18next";
import TextField from '@material-ui/core/TextField';

let sha1 = require('sha1');
const qs = require('query-string');


const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

interface MyPropsStripe {
    stripe:any,
    elements:any,
    onCardChange(card:any):any,
}

interface MyStateStripe {
}

class StripeCardComponent extends React.Component<MyPropsStripe,MyStateStripe> {

    onChange = async (event) => {
        const {stripe, elements} = this.props;

        let card = elements.getElement(CardElement);
        this.props.onCardChange(card);
    };

    render() {
      return <div>
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={this.onChange}/>
        </div>
    }
}



interface MyProps {
    stripe:any,
    elements:any,
    handleResult:any,
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
    step: number,
}

class RegisterPage extends React.Component<MyProps & RouteComponentProps,MyState> {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{'Username': '', 'Password': '','CompanyName': '','Email': '',},
            //formFields:{},
            step:1,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePromoCodeValidation = this.handlePromoCodeValidation.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);
        this.handlePrevStep = this.handlePrevStep.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.onCardChange = this.onCardChange.bind(this);

        this.onPaste = this.onPaste.bind(this);

    }


    handleSubmit(evt) {
        evt.preventDefault();

        let formFields = this.state.formFields;
        let card = formFields.card;


        const acceptCardPolicy = formFields.acceptCardPolicy;
        const acceptTosPolicy = formFields.acceptTosPolicy;


        if(!formFields.InvoiceCompanyName || !formFields.InvoiceStreet
            || !formFields.InvoicePostalCode || !formFields.InvoiceCity || !formFields.InvoiceVAT ){
            toast.error(i18n.t("msg_missing_fields"));
            return;
        }


        if (card == undefined || card == null) {
            toast.error(i18n.t("msg_card_info_not_correct"));
            return;
        }



        let InvoiceCompanyName = formFields.InvoiceCompanyName.trim();
        let InvoiceStreet = formFields.InvoiceStreet.trim();
        let InvoicePostalCode = formFields.InvoicePostalCode.trim();
        let InvoiceCity = formFields.InvoiceCity.trim();
        let InvoiceVAT = formFields.InvoiceVAT.trim();


        // ToDo: comment for .eu
        // if(!(InvoiceVAT.substr(0,3).toLowerCase() == "be0")){
        //     toast.error(i18n.t("msg_vat_must_start_with_be0"));
        //     return;
        // }
        //
        // if(InvoiceVAT.length != 12){
        //     toast.error(i18n.t("msg_vat_must_have_12_chars"));
        //     return;
        // }



        if(InvoiceStreet.length > 50){
            toast.error(i18n.t("msg_address_max_num_of_chars"));
            return;
        }

        let isPostalCodeANumber = /^\d+$/.test(InvoicePostalCode);
        if(!isPostalCodeANumber){
            toast.error(i18n.t("msg_postal_code_should_contain_only_numbers"));
            return;
        }

        if(!acceptCardPolicy || !acceptTosPolicy ){
            toast.error(i18n.t("msg_accept_policies"));
            return;
        }

        formFields['EncPassword'] = sha1(formFields.Password);

        this.setState({loading: true,});

        stripePromise.then(stripe => {
            return stripe.createPaymentMethod({
                type: 'card',
                card: card,
            });
        })
        .then(result => {

            if (result.paymentMethod === undefined || result.paymentMethod.id.length == 0) {
                toast.error("Card failed");
                throw "Card failed";
            }

            formFields.paymentMethodId = result.paymentMethod.id;

            let newFormFields =  Object.assign({}, formFields);
            delete newFormFields.card;

            let language = "en";
            if(i18n.language){
                language = i18n.language;
            }

            return serverService.autoRegisterUser(newFormFields,language);
        })
        .then(response => {
                if(response != undefined){
                    if(response.status == 1 || response.status == "1"){
                        this.setState({ submitted:false, loading: false, formFields: {Username:"", Password:"", CompanyName:"", Email: "", Email2:""} });

                        toast.success(i18n.t("msg_registration_completed"));

                        setTimeout(function(){
                            window.location.href = "/login";
                        }, 3000);
                    } else {
                        toast.warn(response.message);
                        this.setState({ submitted:false, loading: false });
                    }
                }
        }
        , error => {
            this.setState({ submitted:false, loading: false });
            console.log(error);
        });

    }


    onPaste(e) {
      e.preventDefault();
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    handlePromoCodeValidation(e) {
        e.preventDefault();

        this.setState({ submitted: true,loading: true });
        const { formFields } = this.state;
        const PromoCode = formFields.PromoCode;

        if(PromoCode == "MonizzeRocks"){
            formFields.monizzeDiscount = true;
            formFields.freeDiscount = false;
            toast.success(i18n.t("msg_promo_code_is_applied"));
        } else if(PromoCode == "FREE"){
            formFields.freeDiscount = true;
            formFields.monizzeDiscount = false;
            toast.success(i18n.t("msg_promo_code_is_applied"));
        } else {
            formFields.monizzeDiscount = false;
            formFields.freeDiscount = false;
            toast.warn(i18n.t("msg_promo_code_is_not_correct"));
        }
        this.setState({ submitted: false,loading: false, formFields: formFields });
    }


    render() {
        const { formFields,submitted, loading, error,step,} = this.state;

        const Username = formFields.Username;
        const Password = formFields.Password;
        const CompanyName = formFields.CompanyName;
        const Email = formFields.Email;
        const Email2 = formFields.Email2;


        const PromoCode = formFields.PromoCode;

        const InvoiceCompanyName = formFields.InvoiceCompanyName;
        const InvoiceStreet = formFields.InvoiceStreet;
        const InvoicePostalCode = formFields.InvoicePostalCode;
        const InvoiceCity = formFields.InvoiceCity;
        const InvoiceVAT = formFields.InvoiceVAT;

        const PhoneNumber = formFields.PhoneNumber;


        const acceptCardPolicy = formFields.acceptCardPolicy;
        const acceptTosPolicy = formFields.acceptTosPolicy;


        let step1SelectedClass = "dot_selected";
        let step2SelectedClass = "dot";
        if(step == 2){
            step1SelectedClass = "dot";
            step2SelectedClass = "dot_selected";
        }


        let generalConditionsLink = "/assets/pdfs/Conditions generales_horeca_en.pdf";
        if(i18n.language == "fr"){
            generalConditionsLink = "/assets/pdfs/Conditions generales_horeca_fr.pdf";
        } else if(i18n.language == "nl"){
            generalConditionsLink = "/assets/pdfs/Conditions generales_horeca_nl.pdf";
        }



        return (
            <>
            {
                step == 2 &&
                <NotificationBanner/>
            }
            <div className="col-md-8 col-md-offset-3 text-center mx-auto" id="loginContainer">
                <br/>
                <br/>
                <h2 className="register-text">
                    <Trans>
                        register_restaurant
                    </Trans>
                </h2>
                <br/>

                <div className="form-group mt-3 mb-4">
                    <span className={step1SelectedClass}>1</span>
                    &nbsp;&nbsp;
                    <span className={step2SelectedClass}>2</span>
                </div>

                    {step == 1 &&
                        <div>
                            <h4 style={{textAlign:'left'}}>
                                <Trans>
                                    register_user_details
                                </Trans>
                            </h4>
                            <br/>
                            <div className={'form-group' + (submitted && !Username ? ' has-error' : '')}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className="form-field"
                                    name="Username"
                                    label={i18n.t("username")}
                                    value={Username}
                                    placeholder={i18n.t("username")}
                                />
                                {submitted && !Username &&
                                <div className="help-block">
                                    <Trans>
                                        username_is_mandatory
                                    </Trans>
                                </div>
                                }
                            </div>

                            <div className={'form-group' + (submitted && !Password ? ' has-error' : '')}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className="form-field"
                                    label={i18n.t("password")}
                                    name="Password"
                                    value={Password}
                                    placeholder={i18n.t("password")}
                                    type="password"
                                />

                                <div className="help-block" style={{color:'#AAA'}}>
                                    <Trans>
                                        password_requirements
                                    </Trans>
                                </div>

                                {submitted && !Password &&
                                <div className="help-block">
                                    <Trans>
                                        password_is_mandatory
                                    </Trans>
                                </div>

                                }
                            </div>



                            <div className={'form-group' + (submitted && !CompanyName ? ' has-error' : '')}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className="form-field"
                                    label={i18n.t("company_name")}
                                    name="CompanyName"
                                    value={CompanyName}
                                    placeholder={i18n.t("company_name")}
                                />
                                {submitted && !CompanyName &&
                                <div className="help-block">
                                    <Trans>
                                        company_name_is_mandatory
                                    </Trans>
                                </div>
                                }
                            </div>

                            <div className={'form-group' + (submitted && !Email ? ' has-error' : '')}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className="form-field"
                                    label={i18n.t("enter_your_email")}
                                    type="email"
                                    name="Email"
                                    value={Email}
                                    placeholder={i18n.t("enter_your_email")}
                                />
                                {submitted && !Email &&
                                <div className="help-block">
                                    <Trans>
                                        email_is_mandatory
                                    </Trans>
                                </div>
                                }
                            </div>


                            <div className={'form-group' + (submitted && !Email2 ? ' has-error' : '')}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className="form-field"
                                    label={i18n.t("retype_email")}
                                    type="email"
                                    name="Email2"
                                    value={Email2}
                                    placeholder={i18n.t("retype_email")}
                                    onPaste={this.onPaste}
                                />
                                {submitted && !Email2 &&
                                <div className="help-block">
                                    <Trans>
                                        email_is_mandatory
                                    </Trans>
                                </div>
                                }
                            </div>

                        <br/>

                            <div className="form-group">
                                <button className="landing-button has-bg mt-3 btn-login" onClick={this.handleNextStep}>
                                    <Trans>
                                        next_step
                                    </Trans>
                                </button>
                                {loading &&
                                <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </div>
                    }

                    {step == 2 &&

                        <div>
                            <h4 style={{textAlign:'left'}}>
                                <Trans>
                                    register_billing_details
                                </Trans>
                            </h4>
                            <br/>
                                <div className="container">
                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <Trans>
                                                register_license_cost
                                            </Trans>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-12">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                className="form-field"
                                                label={i18n.t("enter_the_promocode")}
                                                name="PromoCode"
                                                value={PromoCode}
                                                placeholder={i18n.t("enter_the_promocode")}
                                            />
                                            <button className="landing-button mt-4" disabled={loading} onClick={this.handlePromoCodeValidation}>
                                                <Trans>
                                                    validate
                                                </Trans>
                                            </button>
                                            {loading &&
                                            <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>

                                    </div>

                                    {formFields.monizzeDiscount &&
                                        <div className="row mb-4">
                                            <div className="col-md-12">
                                                <br/>
                                                <Trans>
                                                    new_price
                                                </Trans>
                                                &nbsp;
                                                <b>
                                                    <Trans>
                                                        promo_price_monizze
                                                    </Trans>
                                                </b>
                                                &nbsp;
                                                <Trans>
                                                    promo_price_period_monizze
                                                </Trans>
                                            </div>
                                        </div>
                                    }

                                    {formFields.freeDiscount &&
                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <br/>
                                            <Trans>
                                                new_price
                                            </Trans>
                                            &nbsp;
                                            <b>
                                                <Trans>
                                                    promo_price_free
                                                </Trans>
                                            </b>
                                            &nbsp;
                                            <Trans>
                                                promo_price_period_free
                                            </Trans>
                                        </div>
                                    </div>
                                    }

                                    <br/>
                                    <br/>

                                    <div className="row mt-4 mb-3">
                                        <div className="col-md-12">
                                            <h4 className="register-text">
                                                <Trans>
                                                    invoice_address
                                                </Trans>
                                            </h4>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={'form-group' + (submitted && !InvoiceCompanyName ? ' has-error' : '')}>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    className="form-field"
                                                    name="InvoiceCompanyName"
                                                    value={InvoiceCompanyName}
                                                    label={i18n.t("company_name")}
                                                    placeholder={i18n.t("company_name")}
                                                />
                                                {submitted && !InvoiceCompanyName &&
                                                <div className="help-block">
                                                    <Trans>
                                                        company_name_is_mandatory
                                                    </Trans>
                                                </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className={'form-group' + (submitted && !InvoiceStreet ? ' has-error' : '')}>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    className="form-field"
                                                    name="InvoiceStreet"
                                                    value={InvoiceStreet}
                                                    label={i18n.t("street")}
                                                    placeholder={i18n.t("street")}
                                                />
                                                {submitted && !InvoiceStreet &&
                                                <div className="help-block">
                                                    <Trans>
                                                        street_is_mandatory
                                                    </Trans>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={'form-group' + (submitted && !InvoicePostalCode ? ' has-error' : '')}>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    className="form-field"
                                                    name="InvoicePostalCode"
                                                    value={InvoicePostalCode}
                                                    label={i18n.t("postal_code")}
                                                    placeholder={i18n.t("postal_code")}
                                                />
                                                {submitted && !InvoicePostalCode &&
                                                <div className="help-block">
                                                    <Trans>
                                                        postal_code_is_mandatory
                                                    </Trans>
                                                </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className={'form-group' + (submitted && !InvoiceCity ? ' has-error' : '')}>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    className="form-field"
                                                    name="InvoiceCity"
                                                    value={InvoiceCity}
                                                    label={i18n.t("city")}
                                                    placeholder={i18n.t("city")}
                                                />
                                                {submitted && !InvoiceCity &&
                                                <div className="help-block">
                                                    <Trans>
                                                        city_is_mandatory
                                                    </Trans>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={'form-group' + (submitted && !InvoiceVAT ? ' has-error' : '')}>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    className="form-field"
                                                    name="InvoiceVAT"
                                                    value={InvoiceVAT}
                                                    label={i18n.t("vat")}
                                                    placeholder={i18n.t("vat")}
                                                />
                                                {submitted && !InvoiceVAT &&
                                                <div className="help-block">
                                                    <Trans>
                                                        vat_is_mandatory
                                                    </Trans>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={'form-group'}>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    className="form-field"
                                                    name="PhoneNumber"
                                                    value={PhoneNumber}
                                                    label={i18n.t("phone_number")}
                                                    placeholder={i18n.t("phone_number")}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4 mb-3 mx-auto" style={{ maxWidth: '384px', width: '100%' }}>
                                        <div className="col-md-12">
                                            <h4 className="register-text">
                                                <Trans>
                                                    payment_card
                                                </Trans>
                                            </h4>
                                        </div>

                                        <div className="col-md-12 mt-3">
                                            <Elements stripe={stripePromise}>
                                                <ElementsConsumer>
                                                    {({ elements, stripe }) => (
                                                        <StripeCardComponent elements={elements} stripe={stripe} onCardChange={this.onCardChange} />
                                                    )}
                                                </ElementsConsumer>
                                            </Elements>

                                            <img src="/assets/images/powered_by_stripe.svg" width={180} style={{float:'right'}}/>
                                        </div>

                                        <div className="col-md-12 text-left mt-5">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    checked={acceptCardPolicy}
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="acceptCardPolicy"
                                                    name="acceptCardPolicy"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label
                                                    className="custom-control-label text-grey"
                                                    htmlFor="acceptCardPolicy"
                                                >
                                                    <Trans>
                                                        register_authorize_card
                                                    </Trans>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-left mt-2">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    checked={acceptTosPolicy}
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="acceptTosPolicy"
                                                    name="acceptTosPolicy"
                                                    onChange={this.handleCheckboxChange}
                                                />
                                                <label
                                                    className="custom-control-label text-grey"
                                                    htmlFor="acceptTosPolicy"
                                                >
                                                    <Trans>
                                                        register_authorize_tos
                                                    </Trans>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            <div className="form-group">
                                <form name="form" onSubmit={this.handleSubmit}>
                                    <button className="landing-button has-bg mt-4" disabled={loading}>
                                        <Trans>
                                            register
                                        </Trans>
                                    </button>
                                    <p className="text-light mb-0 mt-4" onClick={this.handlePrevStep}>
                                        <Trans>
                                            back
                                        </Trans>
                                    </p>
                                    {loading &&
                                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </form>


                                <div>
                                    <div className="col-md-4 mt-4">
                                        <a target="_blank" className="guide-links" href={generalConditionsLink}>
                                            <div className="row align-items-center top-buffer">
                                                <div className="col-md-4 col-3 pr-0 pr-md-2">
                                                    <div className="pdf-placeholder text-center">
                                                        <b>PDF</b>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                        <span className="guide-item-title">
                                              <Trans>
                                                tos_horeca
                                            </Trans>
                                        </span>
                                                    <br />
                                                    <span className="guide-item-date">
                                              <Trans>
                                                tos_horeca
                                            </Trans>
                                        </span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>



                            </div>

                        </div>

                    }

                    {error &&
                        <div className={'alert alert-danger'}>{error}</div>
                    }

            </div>
            </>
        );
    }

    onCardChange(card){
        let formFields = this.state.formFields;
        formFields['card'] = card;
        this.setState({ formFields: formFields });
    }

    handleCheckboxChange(e) {
        const { name, checked } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = checked;
        this.setState({ formFields: formFields });
    }

    handleNextStep(){
        this.setState({ submitted: true });

        let formFields =  this.state.formFields;
        if(formFields.Username && formFields.Password
            && formFields.CompanyName && formFields.Email && formFields.Email2){


            let hasUpperCase = formFields.Password.toLowerCase() != formFields.Password;
            let specialCharsRegX = /^[A-Za-z0-9 ]+$/;
            let hasSpecialChar = !specialCharsRegX.test(formFields.Password);

            if(formFields.Password.length < 8 || !hasUpperCase || !hasSpecialChar){
                toast.warn(i18n.t("password_not_valid"));
                return;
            }


            if(!validateEmail(formFields.Email)){
                toast.error(i18n.t("msg_email_not_valid"));
                return;
            }

            if(formFields.Email2 != formFields.Email){
                toast.error(i18n.t("msg_email_not_the_same"));
                return;
            }


            this.setState({loading: true });
            serverService.checkIfUsernameAlreadyExists(formFields.Username).then(result => {
                if(result.status > 0 && result.data == true){
                    this.setState({loading: false });
                    toast.error(i18n.t("username_already_exists"));
                    return;
                } else {
                    this.setState({step:2, submitted: false, loading: false });
                }
            }, error => {
                console.log(error);
                this.setState({loading: false });
            });
        } else {
            toast.warn(i18n.t("msg_missing_fields"));
        }
    }

    handlePrevStep(){
        this.setState({step:1});
    }


    handleResult(){
        toast("Handle result");
    }

}

function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export { RegisterPage };

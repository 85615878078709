import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col, Form, Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import {emphasize} from "@material-ui/core";

import { Trans } from 'react-i18next';
import i18n from "i18next";

import TextField from '@material-ui/core/TextField';

let sha1 = require('sha1');
const qs = require('query-string');


interface MyProps {
}

interface MyState {
    submitted: boolean,
    loading: boolean,
    error: string,
    formFields:any,
}

class DebugPage extends React.Component<MyProps & RouteComponentProps,MyState> {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            formFields:{},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.setState({ formFields: {howManyRequests:50, periodOfTimeBetweenRequests:500 } });
    }


    handleChange(e) {
        const { name, value } = e.target;
        let formFields = this.state.formFields;
        formFields[name] = value;
        this.setState({ formFields: formFields });
    }


    handleSubmit(e) {
        e.preventDefault();

        const { formFields } = this.state;
        let error = this.state.error;

        const howManyRequests = formFields.howManyRequests;
        const periodOfTimeBetweenRequests = formFields.periodOfTimeBetweenRequests;

        if(howManyRequests > 5000 || periodOfTimeBetweenRequests > 10000){
            toast.warn("Values too big");
            return;
        }


        let startTimestampInSeconds = Math.floor(Date.now() / 1000);

        this.setState({ submitted: true,loading: true});


        let userStr = localStorage.getItem('user');
        let IdUser = 0;
        if (userStr) {
            let userObj = JSON.parse(userStr);
            IdUser = userObj.IdUser;
        }

        let answer = {'TableNumber': "2",'duration': "3",'firstAndLastName': "StressTest Name",
            'Email': "stresstest@test.com", "PhoneNumber": "+403334556776"};


        let i=0;
        setTimeout(function doSomething() {

            i++;

            serverService.insertAnswer(answer, IdUser).then(
                response => {
                    // console.log(response);
                    if(response.status == 0){
                        this.setState({ submitted: false,loading: false});
                    } else {
                        error = "Request number: " + i;

                        if(i == howManyRequests){
                            let endTimestampInSeconds = Math.floor(Date.now() / 1000);

                            error += " Time passed: " + (endTimestampInSeconds - startTimestampInSeconds) + " seconds";
                            this.setState({ submitted: false,loading: false,error: error });
                        } else {
                            this.setState({ error: error});
                        }
                    }
                }, error => {
                    console.log(error);
                    toast.error("Server error");
                    this.setState({ submitted: false,loading: false })
                }
            );

            if(i != howManyRequests){
                setTimeout(doSomething.bind(this), periodOfTimeBetweenRequests);
            }

        }.bind(this), periodOfTimeBetweenRequests);


    }


    render() {
        const { formFields,submitted, loading, error} = this.state;

        const howManyRequests = formFields.howManyRequests;
        const periodOfTimeBetweenRequests = formFields.periodOfTimeBetweenRequests;

        return (
            <div className="col-md-8 col-md-offset-3 text-center mx-auto" id="loginContainer">
                <br/>
                <br/>
                <h2 style={{color:'#0072b4'}}>Stress test</h2>
                <br/>

                <form name="form" className={`text-center`} onSubmit={this.handleSubmit}>
                    <div className={'form-group mb-0' + (submitted && !howManyRequests ? ' has-error' : '')}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            name="howManyRequests"
                            value={howManyRequests}
                            label="How many requests"
                            placeholder="How many requests"
                            onChange={this.handleChange}
                            className="form-field"
                            type="number"
                        />
                    </div>

                    <br/>

                    <div className={'form-group mb-0' + (submitted && !periodOfTimeBetweenRequests ? ' has-error' : '')}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            name="periodOfTimeBetweenRequests"
                            value={periodOfTimeBetweenRequests}
                            label="Period of time between requests (miliseconds)"
                            placeholder="Period of time between requests (miliseconds)"
                            onChange={this.handleChange}
                            className="form-field"
                            type="number"
                        />
                    </div>

                    <br />
                    <Row>
                        <Col>
                            <div className="form-group">
                                <button className="landing-button has-bg mt-2 btn-login" disabled={loading}>
                                    <Trans>
                                        start
                                    </Trans>
                                </button>
                                {
                                    loading &&
                                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </Col>
                    </Row>

                </form>

                {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                }

            </div>

        );
    }


}

export { DebugPage };
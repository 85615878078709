/**
 * Created by alexd on 4/9/2020.
 */


//let domainName = "qrsafe.be";
let domainName = "qrsafe.eu";
//let domainName = "qrsafe.narvaro.eu";

let STRIPE_TEST_PUBLIC_KEY = "pk_test_51HCLmxITZlHFoP6us1bx0QeVUBUMxnzhz6ewIGvWXGW3DqoyVc4noSNKXzUamOH6sNWRYJdu4vB6gPVtM9uzLAZv00Z34Jse90";
let STRIPE_PUBLIC_KEY = "pk_live_51HCLmxITZlHFoP6ubPoSdx9xHzvWm9CMKFMkU6vhhgxmQChXv0yfcxittd7WXiUOIRMSA8VOJI7kU4D5Wyz04P2X00q78wbC5B";


export const config = {
    DOMAIN_NAME: domainName,
    API_BASE_URL: "https://api." + domainName + "",
    STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY
};

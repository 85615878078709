import React from 'react'
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router";
import styled from 'styled-components';

import Button from './Button';

import banner from '../assets/banner.jpg';
import {Trans} from "react-i18next";
import {toast} from "react-toastify";

const MainSectionContainer = styled.div`
  background: url(${banner});
  min-height: 623px;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
`;

const VintageContainer = styled.div`
  height: 100%;
  width: 100%; 
  position: absolute;
  background-color: #00000061;
`;

const Content = styled.div`
  margin-top: -198px;
  position: absolute;
  
  @media (max-width: 769px) {
    margin-top: -260px;
  }
`;

const P = styled.p`
  font-size: 18px
`;

const Description = styled.div`
  margin: 24px 0px;
`;

const StartNowButtonContainer = styled.div`
  width: 300px;
`

const MainSection = () => {
  const history = useHistory();

  return (
    <div>
      <MainSectionContainer>
        <VintageContainer/>
        <Container>
          <Content>
            <h1>
              <Trans>
                landingpage_main_title1
              </Trans>
              <br/>
              <Trans>
                landingpage_main_title2
              </Trans>
            </h1>
            <Description>
              <P>
                <Trans>
                  landingpage_main_details1
                </Trans>
                <br/>
                <Trans>
                  landingpage_main_details2
                </Trans>
              </P>
              <P>
                <Trans>
                  landingpage_main_details3
                </Trans>
                <br/>
                <Trans>
                  landingpage_main_details4
                </Trans>
              </P>
              
              <P>
                <Trans>
                  landingpage_main_details5
                </Trans>
                <br/>
                <Trans>
                  landingpage_main_details6
                </Trans>
              </P>
            </Description>
            <StartNowButtonContainer>
              <Button onClick={()=> {
                history.push('/register')
              }}>
                <Trans>
                  landingpage_main_start_now
                </Trans>
              </Button>
            </StartNowButtonContainer>
          </Content>
        </Container>
      </MainSectionContainer>
    </div>
  )
};

export default MainSection

import React from 'react'
import styled from 'styled-components';

const ButtonContainer = styled.div`
  background: #FF5454;
  padding: 8px 16px;
  border: 1px solid #FF5454;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 200ms;

  &:hover {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #ffffff;
  }
`

const ButtonContainerSecondary = styled(ButtonContainer)`
  color: #FF5454;
  background: transparent;
  
  &:hover {
    color: #ffffff;
    background-color: #FF5454;
    border: 1px solid #FF5454;
  }
`
const Button = (props) => {
  const {children, dataType = "primary"} = props;
  
  if(dataType === 'secondary') {
    return (
      <ButtonContainerSecondary {...props}>
        {children}
      </ButtonContainerSecondary>
    )
  }
  
  return (
    <ButtonContainer {...props}>
      {children}
    </ButtonContainer>
  )
}

export default Button

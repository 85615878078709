import React, { RefObject, useState } from "react";
import Slide, { SlideContent } from "../LandingPage/slides/Slide";
import Header from "../LandingPage/Header";
import Footer from "../LandingPage/Footer";
import { PrimaryButton } from "../LandingPage/Buttons";
import Recaptcha from "react-google-recaptcha";
import supportIcon from "./support.svg";


import "./SupportSlide.css";


import {Trans} from "react-i18next";
import i18n from "i18next";

export type FieldValues = {
	username?: string;
	vat?: string;
	postcode?: number;
	email?: string;
	question?: string;
};

type Props = {
	isActive: boolean;
	slideRef: any;
	reCaptchaSiteKey: string;
	onSubmitQuestion: (data: FieldValues) => void;
};

type FormFieldProps = {
	type: string;
	label: string;
	placeholder?: string;
	name: string;
	onInputChange: (name: string, value: string) => void;
};

const FormField = ({ type, label, name, placeholder, onInputChange }: FormFieldProps) => {
	return (
		<div className="form-field">
			<div className="label">{label}</div>
			<div className="field">
				{type == "textarea" ? (
					<textarea required placeholder={placeholder} rows={4} onChange={(e) => onInputChange(name, e.target.value)} />
				) : (
					<input required placeholder={placeholder} type={type || "text"} onChange={(e) => onInputChange(name, e.target.value)} />
				)}
			</div>
		</div>
	);
};

const AgreementTerms = ({ onCheck }: { onCheck: (state: boolean) => void }) => {
	return (
		<div className="aggreement-container">
			<input id="some-random-id" type="checkbox" onChange={(e) => onCheck(e.target.checked)} />
			<label htmlFor="some-random-id">
				<span className="text">
					<Trans>
						form_acceptance
					</Trans>
				</span>
			</label>
		</div>
	);
};

export default ({ isActive, slideRef, onSubmitQuestion, reCaptchaSiteKey }: Props) => {
	const [fieldValues, setFieldValue] = useState<FieldValues>({});
	const [isCaptchaSolved, setCaptchaSolved] = useState(false);
	const [isTermsAgreed, setTermsAggreed] = useState(false);
	const [captchaError, setCaptchaError] = useState<string | null>(null);
	const [termsError, setTermsAgreeError] = useState<string | null>(null);
	const onInputChange = (name: string, value: string) => {
		setFieldValue({ ...fieldValues, [name]: value });
	};
	const onSendData = (evt: any) => {
		evt.preventDefault();


		if (!isTermsAgreed || termsError) {
			setTermsAgreeError(i18n.t("msg_accept_policies"));
		}
		// else if (!isCaptchaSolved || captchaError) {
		// 	setCaptchaError(i18n.t("msg_support_captcha"));
		// }
		else {
			onSubmitQuestion(fieldValues);
		}
	};
	const onAgreeTerms = (state: boolean) => {
		setTermsAggreed(state);
		if (state) {
			setTermsAgreeError(null);
		}
	};
	const onSolveCaptcha = () => {
		setCaptchaSolved(true);
		setCaptchaError(null);
	};


	let faqLink = "/assets/pdfs/FAQ_en.pdf";
	if(i18n.language == "fr"){
		faqLink = "/assets/pdfs/FAQ_fr.pdf";
	} else if(i18n.language == "nl"){
		faqLink = "/assets/pdfs/FAQ_nl.pdf";
	}

	return (
		<Slide className="SupportSlide" isActive={isActive} slideRef={slideRef}>
			<Header pathname='/support' />
			<SlideContent>
				<div className="content">
					<div className="support-info-panel">
						<div className="icon">
							<img src={supportIcon} width={100} />
						</div>
						<h2 className="header">
							<Trans>
								support_title
							</Trans>
						</h2>
						<div className="msg-1">
							<Trans>
								support_description1_part1
							</Trans>
							&nbsp;
							<a href={faqLink}>
								<Trans>
									support_description1_linktext
								</Trans>
							</a>
							&nbsp;
							<Trans>
								support_description1_part2
							</Trans>
						</div>
						<div className="msg-2">
							<Trans>
								support_description2
							</Trans>
						</div>
					</div>
					<div className="form-container">
						<form onSubmit={onSendData}>
							<FormField type="text" label={i18n.t("username")}
									   placeholder={i18n.t("support_username")} name="username" onInputChange={onInputChange} />
							<FormField type="text" label={i18n.t("vat")}
									   placeholder={i18n.t("support_vat")} name="vat" onInputChange={onInputChange} />
							<FormField type="number" label={i18n.t("support_postcode")}
										   placeholder={i18n.t("support_postcode")} name="postcode" onInputChange={onInputChange} />
							<FormField type="email" label={i18n.t("email")}
									   placeholder={i18n.t("support_email")} name="email" onInputChange={onInputChange} />
							<FormField type="textarea" label={i18n.t("your_question")}
									   placeholder={i18n.t("support_question")} name="question" onInputChange={onInputChange} />
							{termsError && <div className="terms-error">{termsError}</div>}
							<AgreementTerms onCheck={onAgreeTerms} />
							<div className="send-container">
								<div className="recaptcha-container">
									{captchaError && <div className="recaptcha-error">{captchaError}</div>}
									<Recaptcha sitekey={reCaptchaSiteKey} onChange={onSolveCaptcha} />
								</div>
								<div className="send-action">
									<PrimaryButton text={i18n.t("support_send")} style={{ padding: "15px 20px", width: "100%" }} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</SlideContent>
		</Slide>
	);
};

import React from 'react'
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import i18n from "i18next";

const FooterOuterContainer = styled.div`
  background-color: #151515;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 769px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .links {
      margin-top: 8px;
    }
  }
`;


const LinkItem = styled.a`
  color: #ccc;
  margin-left:16px;
  
  &:hover {
    color: #fff;
    border-bottom: 1px solid #fff;
  }
`

const Footer = () => {

  let privacyPolicyLink = "/assets/pdfs/privacy policy_en.pdf";
  if(i18n.language == "fr"){
    privacyPolicyLink = "/assets/pdfs/privacy policy_fr.pdf";
  } else if(i18n.language == "nl"){
    privacyPolicyLink = "/assets/pdfs/privacy policy_nl.pdf";
  }


  let generalConditionsHorecaLink = "/assets/pdfs/Conditions generales_horeca_en.pdf";
  if(i18n.language == "fr"){
    generalConditionsHorecaLink =  "/assets/pdfs/Conditions generales_horeca_fr.pdf";
  } else if(i18n.language == "nl"){
    generalConditionsHorecaLink =  "/assets/pdfs/Conditions generales_horeca_nl.pdf";
  }
  
  return (
    <FooterOuterContainer>
      <Container>
        <ContentContainer>
          <div>
            <Trans>all_rights_reserved</Trans>, QRsafe.eu (c) 2020
          </div>
          
          <div className="links">
            <LinkItem href={privacyPolicyLink} target="_blank"><Trans>privacy_policy</Trans></LinkItem>
            <LinkItem href={generalConditionsHorecaLink} target="_blank"><Trans>tos_horeca</Trans></LinkItem>
            <LinkItem href='/support'><Trans>contact_us</Trans></LinkItem>

          </div>
        </ContentContainer>
      </Container>
    </FooterOuterContainer>
  )
}

export default Footer

import React, { useState } from 'react'
import Container from '@material-ui/core/Container';
import styled from 'styled-components';

import { useHistory } from "react-router";

import logo from '../assets/logo.png';
import menuIcon from '../assets/menu-button.svg';
import Button from './Button';

import i18n from "i18next";
import {Trans} from "react-i18next";
import LanguageSelect from "../../_other/LanguageSelect";


const HeaderContainer = styled.div`
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 769px) {
    border-bottom: 1px solid #ccc;
  }
`;

const Logo = styled.img`
  width: 200px;
`;


const Navigation = styled.div`
  width: 272px;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 769px) {
    display: none;
  }
`;

const MobileNavigation = styled.div`
  display: none;
  
  @media (max-width: 769px) {
    display: block;
  }
`;


const MobileNavigationContainer = styled.div`
  display: none;
  
  @media (max-width: 769px) {
    display: block;
  }
`;

const MobileNavigationItem = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  padding: 16px 0px;
`;

const HeaderInner = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = () => {
  const [menuStatus, setMenuStatus] = useState<Boolean>(false);
  const history = useHistory();

  return (
    <>
      <HeaderContainer>
        <Container>
          <HeaderInner>
            <Logo src={logo}>
            </Logo>

            <Navigation>
              <Button dataType="secondary" onClick={()=> {
                history.push('/form')
              }}>
                <Trans>
                  landing_customer_button
                </Trans>
              </Button>
              <Button dataType="secondary" onClick={()=> {
                history.push('/login')
              }}>
                <Trans>
                  landing_login
                </Trans>
              </Button>
            </Navigation>

            <MobileNavigation onClick={()=>setMenuStatus(!menuStatus)}>
              <img src={menuIcon} height={32} />
            </MobileNavigation>
          </HeaderInner>
        </Container>
        <div style={{marginRight:"30px"}}>
          <LanguageSelect
              onLanguageSelected={onLanguageSelected}
              selectedLanguage={i18n.language}
          />
        </div>
      </HeaderContainer>
      
      {
        menuStatus &&
        <MobileNavigationContainer>
          <MobileNavigationItem>
            <Trans>
              landing_customer_button
            </Trans>
          </MobileNavigationItem>
          <MobileNavigationItem>
            <Trans>
              landing_login
            </Trans>
          </MobileNavigationItem>
        </MobileNavigationContainer>
      }
    </>
  )
}

function onLanguageSelected(LanguageName) {
  localStorage.setItem('language',LanguageName);
  i18n.changeLanguage(LanguageName);

  // console.log(pathname);
  // window.location.reload();
  window.location.href = "/hello";
}


export default Header

import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { config } from '../../_helpers/C'

import AnswersTable from "./AnswersTable";

import ModalColorPicker from "./ModalColorPicker";

import { Trans } from "react-i18next";
import i18n from "i18next";
import ModalColorPickerTest from "./ModalColorPickerTest";

interface MyProps {
}

interface MyState {
    loading: boolean,
    modalColorPickerShow: boolean,
}

class HomePage extends React.Component<MyProps, MyState> {

    private loggedInUser;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            modalColorPickerShow: false,
        };

        this.downloadPdfBtnClick = this.downloadPdfBtnClick.bind(this);
        this.modalColorPickerClose = this.modalColorPickerClose.bind(this);
        this.modalColorPickerProceed = this.modalColorPickerProceed.bind(this);
        this.handleManageBillingClick = this.handleManageBillingClick.bind(this);

    }

    componentDidMount() {
        console.log("componentDidMount");
        let userStr = localStorage.getItem('user');
        if (userStr) {
            this.loggedInUser = JSON.parse(userStr);

        }
    }

    render() {

        if(this.loggedInUser){

            let formLink = "https://" + config.DOMAIN_NAME + "/form?c=qr" + this.loggedInUser.IdUser;
            let qrCodeLink = config.API_BASE_URL + "/img/user_" + this.loggedInUser.IdUser + ".png";

            return (
                <div id="homeContainer">

                    {this.state.loading &&
                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }

                    <div className="text-center">
                        <h2 className="register-text text-center mb-3">{this.loggedInUser.CompanyName}</h2>
                        <img src={qrCodeLink} />
                        <br />
                        <a href={formLink}>{formLink}</a>
                        <br />
                        <button className="landing-button has-bg mt-4" style={{ maxWidth: '384px' }} onClick={this.downloadPdfBtnClick}>
                            <Trans>
                                download_pdf
                            </Trans>
                        </button>
                    </div>

                    <br />
                    <br />
                    <br />

                    <AnswersTable />

                    <br />
                    <br />

                    <div className="col-md-6 col-md-offset-3 text-center mx-auto" id="loginContainer">

                        {(this.loggedInUser.IdStripe != undefined && this.loggedInUser.IdStripe.length > 0) &&
                        <button className="landing-button has-bg mt-4" onClick={this.handleManageBillingClick}>
                            <Trans>
                                manage_billing
                            </Trans>
                        </button>
                        }

                    </div>

                    <ModalColorPicker
                        show={this.state.modalColorPickerShow}
                        onConfirmNo={this.modalColorPickerClose}
                        onHide={this.modalColorPickerClose}
                        onConfirmYes={this.modalColorPickerProceed}
                    />

                </div>
            );
        } else {
            return null;
        }

    }


    downloadPdfBtnClick() {
        this.setState({ modalColorPickerShow: true });
    }

    modalColorPickerClose() {
        this.setState({ modalColorPickerShow: false });
    }

    modalColorPickerProceed(formFields) {
        this.setState({ modalColorPickerShow: false, loading: true });
        let selectedColor = formFields.color;

        let language = localStorage.getItem('language');
        if(language == undefined || language.length == 0){
            language = "en";
        }

        serverService.generatePdf(selectedColor,language)
            .then(data => {
                this.setState({ loading: false });

               let pdfLink = config.API_BASE_URL + "/pdfs/user_" + this.loggedInUser.IdUser + ".pdf";
               window.open(pdfLink);

            }, error => {
                toast.warn("Error while generating file");
                console.log(error);
                this.setState({ loading: false });
            });
    }


    handleManageBillingClick () {
        serverService.createCustomerPortalSession()
            .then(response => {
                    if(response.status == "0"){
                        toast.warn(response.message);
                    } else {
                        if(response.data.length > 0){
                            window.location.href = response.data;
                        } else {
                            toast.warn(i18n.t("something_went_wrong"));
                        }
                    }
                },
                error => {
                    console.log(error);
                });
    }

}


export { HomePage };